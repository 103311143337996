<template>
  <div class="p-1 md:self-start">
    <ClientOnly>
      <div
        v-click-away="clickAway"
        class="min-w-[60px] overflow-hidden transition-all duration-250 ease-in-out md:text-base md:font-medium"
        :style="`--target-height-mobile: ${languages.length * 40}px`"
        :class="{
          'h-8': !langSwitchDropdownOpen,
          'h-[var(--target-height-mobile)]': langSwitchDropdownOpen,
        }"
      >
        <button
          class="flex w-full items-center p-2 px-4 transition-all duration-250 ease-in-out hover:text-primary-500 lg:justify-between"
          :class="{
            'opacity-50': languageLinks.length === 0,
          }"
          :disabled="languageLinks.length === 0"
          @click="langSwitchDropdownOpen = !langSwitchDropdownOpen"
        >
          <span class="pr-2">
            {{
              currentLanguage[0].toUpperCase() + currentLanguage.slice(1)
            }}</span
          >
          <SpriteSymbol
            name="chevron-down"
            class="size-6 text-body transition-all duration-250 ease-in-out"
            :class="{
              'rotate-180': langSwitchDropdownOpen,
            }"
          />
        </button>
        <div
          class="relative left-0 z-30 w-max min-w-full rounded border border-gray-300 bg-white pl-2 pr-4 text-sm shadow-md transition-all duration-250 ease-in-out"
        >
          <span
            class="absolute left-0 top-0 -mt-1 ml-6 size-3 rotate-45 border bg-white"
          />
          <ul class="relative w-full rounded bg-white">
            <li v-for="(lang, i) in languageLinks" :key="`lang_${i}`">
              <a
                :href="lang.to"
                class="flex cursor-pointer items-center p-2 px-4 transition-all duration-250 ease-in-out hover:text-primary-500"
              >
                {{ lang.code[0].toUpperCase() + lang.code.slice(1) }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import type { ComputedRef } from 'vue'

type LanguageLink = {
  code: string
  active: boolean
  to: string
}

const languages: ComputedRef<LanguageLink[]> = useLanguageLinks()
const currentLanguage = useCurrentLanguage()
const languageLinks = computed(() => {
  return languages.value.filter((l) => l.code !== currentLanguage.value)
})

const langSwitchDropdownOpen = ref(false)
const clickAway = () => {
  if (langSwitchDropdownOpen.value) {
    langSwitchDropdownOpen.value = false
  }
}
</script>
