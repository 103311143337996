<template>
  <nav
    class="pb-8 text-primary-500 md:px-2 md:pb-0"
    @mouseleave="onNavbarMouseLeave"
  >
    <ul
      class="container flex size-full flex-col flex-wrap text-2xl md:flex-row md:items-center md:gap-2 md:text-base md:font-medium"
    >
      <li
        v-for="(link, index) in links"
        :key="`global_${index}`"
        class="group relative flex flex-col items-stretch pt-3 md:flex-row md:py-3"
      >
        <VuepalLink
          :to="link.link?.url?.path"
          class="flex h-[50px] w-full items-center px-4 pl-2 transition-all duration-250 ease-in-out group-hover:text-primary-400 md:size-auto md:px-3"
          :class="
            link.link?.url?.path === currentActiveMainLink?.link.url?.path
              ? 'text-primary-400'
              : 'text-primary-500'
          "
          @mouseenter="onMouseEnter(index)"
          @mouseleave="onMouseLeave"
        >
          {{ link.link.label }}
          <SpriteSymbol
            v-if="link.subtree.length && !isMobile"
            name="chevron-down"
            class="ml-2 size-5 transition-all duration-250 ease-in-out group-hover:-rotate-180 group-hover:text-primary-400"
            :class="
              link.link?.url?.path === currentActiveMainLink?.link.url?.path
                ? 'text-primary-400'
                : 'text-primary-500'
            "
          />
        </VuepalLink>
        <div
          v-show="hover === index || isMobile"
          class="z-30 mt-1 w-max min-w-full pl-2 pr-4 text-sm transition-all duration-250 ease-in-out md:absolute md:left-0 md:top-10 md:rounded md:border md:border-gray-300 md:bg-gray-50 md:shadow-md"
        >
          <span
            v-if="!isMobile"
            class="absolute left-0 top-0 -mt-1 ml-6 hidden size-3 rotate-45 border bg-white md:block"
          />
          <div class="relative z-10 w-full py-1 md:rounded md:bg-gray-50">
            <ul>
              <li v-for="(child, i) in link.subtree" :key="i" class="relative">
                <VuepalLink
                  :to="child.link.url?.path"
                  class="flex w-full cursor-pointer items-start py-2 no-underline transition-colors duration-100 hover:text-primary-400 hover:no-underline md:px-4"
                >
                  <span class="flex-1">{{ child.link.label }}</span>
                </VuepalLink>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import { useViewport } from '#imports'

const { links, currentActiveMainLink } = await useMainMenuLinks()
const hasTransition = ref(false)
const hover = ref(-1)
let timeout: any = null
const viewport = useViewport()
const isMobile = ref(viewport.isLessThan('lg'))

watch(viewport.breakpoint, (newValue: string) => {
  isMobile.value = !(
    newValue === 'md' ||
    newValue === 'lg' ||
    newValue === 'xl'
  )
})

function setActive(index: number, toggle?: boolean) {
  const hasChildren = !!links.value[index]?.subtree.length
  if (!hasChildren) {
    hover.value = -1
    return
  }
  hasTransition.value = index === -1 || hover.value === -1
  // Behavior when toggling is requested.
  if (toggle) {
    hover.value = hover.value === index ? -1 : index
    return
  }
  hover.value = index
}

function onMouseEnter(index: number) {
  clearTimeout(timeout)
  timeout = setTimeout(() => {
    setActive(index)
  }, 100)
}

function onMouseLeave() {
  clearTimeout(timeout)
}

function onNavbarMouseLeave() {
  hover.value = -1
}
</script>
