<template>
  <header
    ref="container"
    class="page-header-grid top-0 z-10 grid border-b-2 border-b-primary-300 bg-gray-50 py-2 transition-all duration-250 ease-in-out"
    :class="{
      'max-h-[65px] !overflow-y-hidden md:max-h-screen md:!overflow-y-visible':
        !isMenuOpen,
      'max-h-screen overflow-y-auto': isMenuOpen,
      'fixed w-full': isGlobalSticky,
      sticky: !isGlobalSticky,
    }"
  >
    <SectionHeaderLogo :is-sticky="isGlobalSticky" class="grid-area-logo" />
    <SectionHeaderGlobalMenu ref="globalMenu" class="grid-area-global" />
    <SectionHeaderSearchLink
      ref="searchLink"
      class="grid-area-search"
      :is-sticky="isGlobalSticky"
    />
    <SectionHeaderLanguageSwitch ref="langSwitch" class="grid-area-context" />

    <button
      class="grid-area-switch mr-4 flex items-center gap-3 leading-none md:hidden"
      aria-label="Hamburger Menu"
      @click="toggleNav"
    >
      <span
        class="h-auto leading-none opacity-100"
        :class="{
          'opacity-0 transition-all duration-250 ease-in-out': isMenuOpen,
        }"
      />
      <SectionHeaderMenuIcon :is-in-closable-state="isMenuOpen" />
    </button>
  </header>
</template>

<script lang="ts" setup>
import {
  type PageHeaderGlobalMenu,
  PageHeaderLanguageSwitch,
  PageHeaderSearchLink,
} from '#components'

const globalMenu = ref<typeof PageHeaderGlobalMenu | null>(null)
const searchLink = ref<typeof PageHeaderSearchLink | null>(null)
const container = ref<HTMLElement | null>(null)
const isGlobalSticky = ref(false)
const isMenuOpen = ref(false)
const viewport = useViewport()

const emit = defineEmits([
  'menu:open',
  'menu:close:start',
  'menu:close:finished',
])

function toggleNav() {
  if (!isMenuOpen.value) {
    openMenu()
  } else {
    closeMenu()
  }
}
function openMenu() {
  emit('menu:open')
  isMenuOpen.value = true
  isGlobalSticky.value = true
}

function closeMenu() {
  emit('menu:close:start')
  isMenuOpen.value = false
  if (container.value) {
    // We need to manually reset the scroll position inside the menu container,
    // so it doesn't
    container.value.scrollTop = 0
  }

  setTimeout(() => {
    emit('menu:close:finished')
    isGlobalSticky.value = false
  }, 250)
}

watch(viewport.breakpoint, (newValue: string) => {
  if (newValue === 'md' || newValue === 'lg' || newValue === 'xl') {
    isMenuOpen.value = false
  }
})
</script>

<style lang="postcss">
.page-header-grid {
  grid-template:
    'logo switch' theme(space.10)
    'local local' 1fr
    'global global' auto
    'search context' auto
    / 1fr auto;

  @screen md {
    grid-template:
      'logo global search context' 50px
      'local local local local' auto
      / auto 1fr auto auto;
  }
}

.grid-area-logo {
  grid-area: logo;
}

.grid-area-global {
  grid-area: global;
}

.grid-area-switch {
  grid-area: switch;
}

.grid-area-context {
  grid-area: context;
}

.grid-area-search {
  grid-area: search;
}
</style>
