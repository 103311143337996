<template>
  <footer
    class="z-10 mt-8 border-t-2 border-t-primary-300 bg-gray-50 py-2 transition-all duration-250 ease-in-out"
  >
    <div class="page-footer-grid container mx-auto grid">
      <SectionHeaderLogo :is-sticky="false" class="grid-area-logo pl-0 md:pl-0" />
      <div class="grid-area-copyright flex items-center text-gray-800">
        <a target="_blank" href="https://liip.ch"> &copy; {{ copyright }} </a>
      </div>
      <ScrollTopButton class="grid-area-top" />
    </div>
  </footer>
</template>

<script lang="ts" setup>
const { $texts } = useNuxtApp()
const copyright = computed(() => {
  const year = new Date().getFullYear().toString()
  return $texts('footer.copyright', '@year Liip AG').replace('@year', year)
})
</script>

<style lang="postcss">
.page-footer-grid {
  grid-template:
    'logo top' theme(space.10)
    'copyright local' 1fr
    'global global' auto
    'search context' auto
    / 1fr auto;

  @screen md {
    grid-template:
      'logo copyright language top' 50px
      / auto 1fr auto auto;
  }
}

.grid-area-logo {
  grid-area: logo;
}

.grid-area-copyright {
  grid-area: copyright;
}

.grid-area-switch {
  grid-area: switch;
}

.grid-area-top {
  grid-area: top;
}

.grid-area-search {
  grid-area: search;
}
</style>
