<template>
  <div
    class="flex items-center pl-4 pr-1 text-white md:pl-12"
    :class="{
      'sticky top-0': isSticky,
    }"
  >
    <nuxt-link :to="{ name: 'home' }" :aria-label="$texts('home', 'Home')">
      <SpriteSymbol
        name="logo"
        alt="Starterkit logo"
        class="aspect-[128/32] h-10"
      />
    </nuxt-link>
    <h2 class="sr-only">Starterkit Logo</h2>
  </div>
</template>

<script lang="ts" setup>
const { $texts } = useNuxtApp()

defineProps<{
  isSticky: boolean
}>()
</script>
