<template>
  <div
    class="pr-4 font-medium md:pr-12"
    :class="{
      'sticky top-0': isSticky,
    }"
  >
    <nuxt-link
      :to="{ name: 'search' }"
      class="flex h-10 items-center gap-1 px-3 text-primary-500 md:h-[50px]"
    >
      <span class="sr-only md:not-sr-only">
        {{ $texts('header.search', 'Suche') }}
      </span>
      <SpriteSymbol name="magnifyingglass" class="size-5" />
    </nuxt-link>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  isSticky: boolean
}>()

const { $texts } = useNuxtApp()
</script>
